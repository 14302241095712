<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <iframe
        :src="`/sso/index.html`"
        width="100%"
        height="100%"
        ref="iframeDom"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'login',
  mounted() {
    //监听iframe中WebSocket返回的状态值
    window.addEventListener('message', (eve) => {
      if (eve.data.result) {
	    setTimeout(() => {
			// this.$router.push({
			// 	path: '/#/',
			// 	query: eve.data.query
			// })
			window.location.href = '/#/?token=' + eve.data.query.token + '&lan=' + eve.data.query.lan
			window.location.reload()
			// this.$emit('setLoginFlag', true)
		},500)

      }
    })
  }
}
</script>

<style scoped>
iframe {
  border: 0
}
</style>
